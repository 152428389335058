
export default{
    // API_BASE_URL: 'http://localhost:8000',
    path:'https://stock.storefy.ma/back/storage/app/public/',
    API_BASE_URL:'https://stock.storefy.ma/back/public'

    // API_BASE_URL: 'http://app.zenamarketingnegoce.com/back/public',
    // path:'http://app.zenamarketingnegoce.com/back/storage/app/public/',
}


// export const API_BASE_URL = '${config.API_BASE_URL}/';

